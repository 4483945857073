import React from 'react';
import { Component,useEffect } from "react";
import Navbar from '../components/navbar';
import Footer from "../components/footer";
import Body2 from '../components/body2';
import './styles.css';
import wave from '../assets/wave.mp4';
import BodyDefi2 from '../components/bodyDefi2';
import ReactGA from 'react-ga';

class Defi extends Component {
    render(){
        return (
            <div>
                <Navbar/>
                <Body2/>
                <BodyDefi2/>
                <Footer/>
            </div>
        );
    }
    
}

export default Defi;