import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Grid from '@mui/material/Grid';
import { FaDiscord } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import TelegramIcon from '@mui/icons-material/Telegram';
var { SocialIcon } = require('react-social-icons');

export default function Footer() {
  return (
    <Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        borderColor: '#28283b',
      }}
        backgroundColor= "#1A1A2C"
        style={{padding:"5%",marginBottom:"-5%", marginLeft:"10%", marginRight:"10%"}}
      >  
        <Typography variant="body1" color="#FAFAFD" fontFamily= "figtree" fontSize="16px">
          Yasal Uyarı <br/>
        </Typography>
        <Typography  color="#FAFAFD" fontFamily= "figtree" fontSize="14px">
          dYdX borsasında işlem yapmanın, platformun kendisinde veya sağlanan bağlantılar aracılığıyla bulunabilen Kullanım Koşullarına 
          tabi olduğunu lütfen unutmayın. Kullanıcıların dYdX üzerinde herhangi bir alım satım faaliyetinde bulunmadan önce <a href='https://dydx.exchange/terms' style={{color: "white"}}> Kullanım Koşullarını</a>  
          &nbsp; incelemeleri ve anlamaları tavsiye edilir.
        </Typography>
      </Box>
      <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: '#28283b',
      }}
        backgroundColor= "#1A1A2C"
        style={{marginTop:"3%"}}
      >     
        <Box
          component="footer"
          sx={{
              py: 3,
              px: 2,
              mt: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
          }}
        >
          <Container>
          <Grid container justifyContent="center" alignItems="center" spacing={2}>
                  <Grid item xs={12} md={12}>
                      <Box>
                          <IconButton aria-label="Youtube" color="#C8C7D8">
                              <a href="https://www.youtube.com/@dYdXTurkiye">
                                  <YouTubeIcon style={{ color: "#C8C7D8" }}/>
                              </a>
                          </IconButton>
                          <IconButton aria-label="Twitter" color="#C8C7D8">
                              <a href="https://twitter.com/dYdXTurkiye">
                                  <TwitterIcon style={{ color: "#C8C7D8" }} />
                              </a>
                          </IconButton>
                          <IconButton aria-label="Discord" color="#C8C7D8">
                              <a href="http://discord.gg/fFfeedMdGn">
                                  <FaDiscord style={{ color: "#C8C7D8" }} />
                              </a>
                          </IconButton>
                          <IconButton aria-label="Telegram" color="#C8C7D8">
                              <a href="https://t.me/dydxturkiye">
                                  <TelegramIcon style={{ color: "#C8C7D8" }} />
                              </a>
                          </IconButton>
                          <IconButton aria-label="Medium" color="#C8C7D8">
                              <SocialIcon url="https://medium.com/@dYdXTurkey" fgColor="#C8C7D8" bgColor="#1A1A2C" style={{ height: 35, width: 35, marginLeft:"-50%", marginTop:"-20%" }}/>
                          </IconButton>
                      </Box>
                  </Grid>
              </Grid>
          </Container>      
        </Box>
      </Box>
    </Box>

  );
}