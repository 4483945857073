import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import YoutubeEmbed from "./youTubeEmbed.js";
import "../App.css";
import ReactGA from 'react-ga';
import { useEffect } from 'react';

export default function Body2() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, []);
  return (
    <Container>
       <Grid container spacing={2} style={{paddingTop: "3%"}}>
            <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
                <div className='yazı'>
                    <Typography className="circular-text" color="#ffffff" fontSize={24}  fontWeight='bold' style={{fontFamily: "figtree"}} sx={{ textAlign: 'left' }}>
                        Geleceğin Finansı: DeFi
                    </Typography>
                    <Typography style={{textAlign: "justify", fontFamily: "figtree"}} fontSize={18} sx={{ textAlign: 'left' }} color="#ffffff">
                        <br/>
                        DeFi'yi en iyi şekilde öğrenmek için topluluk gücünü kullan. <br/><br/>
                        dYdX Türkiye Discord'una katılarak DeFi'nin en aktif komunitesi ile fikir alışverişi yap ve deneyimlerinden faydalan.<br/><br/>
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "flex-start", marginLeft:"-4%" }}>
                            <a className="guess-button" href="https://mee6.xyz/i/P0F495v1Lo" style={{ display: "flex", fontFamily: "figtree", alignItems: "center", justifyContent: "center", marginLeft: "5%", backgroundColor: "#5a59d8", textDecoration: "none" }}>
                                <Typography color="#ffffff" fontSize={14} fontWeight='bold'>
                                    dYdX Türkiye <br/> Discord'una Katıl
                                </Typography>
                            </a>  
                        </div>
                    </div>
            </Grid>
            <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
                <YoutubeEmbed embedId="iLi6JNPME8U" />
            </Grid>
        </Grid>
    </Container>      
  );
}