import KriptoPara from './pages/KriptoPara';
import Defi from './pages/Defi';
import Dydx from './pages/Dydx';
import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Sitemap from './pages/sitemap'
import Welcome from './pages/Welcome';
// Google Analytics'ı başlat

// Google Analytics'ı başlat
function App() {

  return (
    <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/defi" element={<Defi />} />
            <Route path="/kriptoPara" element={<KriptoPara />} />
            <Route path="/dydx" element={<Dydx />} />
            <Route path="/sitemap.xml" element={<Sitemap />} />
          </Routes>
        </Router>
    </div>
  );
}

export default App;