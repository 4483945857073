import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import "../App.css";
import kalkan from '../assets/kalkan.png'; // with import
import kure from '../assets/kure.png'; // with import
import dot from '../assets/dots2.png'; // with import
import net from '../assets/2 1.png'; // with import
import ai from '../assets/ai.svg'; // with import
import pc from '../assets/pc.svg'; // with import
import YoutubeEmbed from "./youTubeEmbed.js";

export default function BodyKripto3() {
  return (
    <Container>
       <Grid container spacing={2}>
            <Grid item xs={12} md={9} sx={{ order: { xs: 1, md: 2 } }}>
                <div className='yazı2' style={{backgroundColor: "#181825"}}>
                    <Typography color="#ffffff" fontSize={24} fontFamily= "figtree" fontWeight='bold' sx={{ textAlign: 'left' }}>
                        Değiştirilemezlik ve Şeffaflık
                    </Typography>
                    <Typography style={{textAlign: "justify", fontFamily: "figtree"}} fontSize={18} sx={{ textAlign: 'left' }} color="#ffffff">
                        <br/>
                        Blok zincirine bir blok eklendiğinde, işlemler kalıcıdır ve değiştirilemez, bu da onu finansal işlemler için en uygun araç haline getirir.
                        <br/><br/>
                        ‍Kripto para birimi işlemleri şeffaftır ve blok zincirinde görüntülenebilir, bu da finansal sistemlerde güven ve hesap verebilirliği artırır.
                        <br/><br/>
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={12} md={3} sx={{ order: { xs: 2, md: 1 } }}>
                <div className="token-img-container" style={{marginLeft: "10%"}}>
                    <img src={net} alt="net" className="token-img" />
                </div>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={12} md={9} >
                <div className='yazı' style={{backgroundColor: "#181825"}}>
                    <Typography color="#ffffff" fontSize={24} fontFamily= "figtree" fontWeight='bold' sx={{ textAlign: 'left' }}>
                        Merkeziyetsizlik ve Güvenlik
                    </Typography>
                    <Typography style={{textAlign: "justify", fontFamily: "figtree"}} fontSize={18} sx={{ textAlign: 'left' }} color="#ffffff">
                        <br/>
                        Çoğu kripto para merkezi olmayan bir yapıda olup tek bir otorite ya da aracı tarafından kontrol edilememektedir, bu da onları sansüre, hacklenmeye karşı dirençli ve daha şeffaf hale getirir.
                        <br/><br/>
                        Kriptografik teknoloji, kullanıcılara fonlarının güvenliğini sağlamak için geri alınamaz kriptografik kanıtlar sağlayarak kripto para birimlerini güvence altına alır.
                        <br/><br/>
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={12} md={3} >
                <div className="token-img-container" style={{marginLeft: "10%"}}>
                    <img src={kalkan} alt="kalkan" className="token-img" />
                </div>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={12} md={9} sx={{ order: { xs: 1, md: 2 } }}>
                <div className='yazı2' style={{backgroundColor: "#181825"}}>
                    <Typography color="#ffffff" fontSize={24} fontFamily= "figtree" fontWeight='bold' sx={{ textAlign: 'left' }} >
                        Dünyanın Her Yerinde, Anında
                    </Typography>
                    <Typography style={{textAlign: "justify", fontFamily: "figtree"}} fontSize={18} sx={{ textAlign: 'left' }} color="#ffffff">
                        <br/><br/>
                        Kripto para birimlerini, pahalı işlem ücretleri, transfer limitleri, bekleme süreleri ve geleneksel finansla (TradFi) ilgili diğer zorluklar olmadan dünyanın neresinde olursanız olun kullanın.
                        <br/><br/><br/><br/>
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={12} md={3} sx={{ order: { xs: 2, md: 1 } }}>
                <div className="token-img-container" style={{marginLeft: "10%"}}>
                    <img src={kure} alt="küre" className="token-img" />
                </div>
            </Grid>
        </Grid>
        <div className='yazı3' style={{backgroundColor: "#181825"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                        <Typography color="#ffffff" fontSize={24} fontFamily= "figtree" fontWeight='bold' sx={{ textAlign: 'left' }}>
                            Varlıklarının Gerçek Sahibi Ol
                        </Typography>
                        <Typography style={{textAlign: "justify", fontFamily: "figtree"}} fontSize={18} sx={{ textAlign: 'left' }} color="#ffffff">
                            <br/>
                            Merkezi Finansal kurumların aksine, DeFi kullanıcıları fonlarının kontrolünü ellerinde tutarken izinsiz bir şekilde finansal hizmetlere erişimlerini sağlar. <br/><br/>
                            Kullanıcılar varlıklarını blockchain teknolojisi güvencesiyle sadece kendi erişimlerinin olduğu cüzdanlarda tutar, herhangi bir otorite tarafından izin almadan istedikleri gibi kullanabilirler.<br/><br/>
                        </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <div className="token-img-container" style={{marginLeft: "10%"}}>
                        <img src={ai} alt="ai" className="token-img" />
                    </div>
                </Grid>
            </Grid>
        </div>
        <div className='yazı3' style={{backgroundColor: "#181825"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <div className="token-img-container" style={{marginLeft: "10%"}}>
                        <img src={pc} alt="pc" className="token-img" />
                    </div>
                </Grid>
                <Grid item xs={12} md={9}>
                        <Typography color="#ffffff" fontSize={24} fontFamily= "figtree" fontWeight='bold' sx={{ textAlign: 'left' }}>
                            Sadece Koda Güven
                        </Typography>
                        <Typography style={{textAlign: "justify", fontFamily: "figtree"}} fontSize={18} sx={{ textAlign: 'left' }} color="#ffffff">
                            <br/>
                            Blockchain teknolojisi, blockchain ağında saklanan akıllı sözleşmelerin, yazılım ve koda dayalı anlaşmaların kullanılmasını sağlayarak aracılara olan ihtiyacı ortadan kaldırır. Kripto para birimleri, bu akıllı sözleşmelerle etkileşime geçmek için kullanılabilir.
                            <br/><br/>
                        </Typography>
                </Grid>
                
            </Grid>
        </div>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
                <div className='yazı'>
                <Typography color="#ffffff" fontSize={24} fontFamily= "figtree" fontWeight='bold' sx={{ textAlign: 'left' }}>
                    Topluluk Gücünü Kullan
                </Typography>
                <Typography style={{textAlign: "justify", fontFamily: "figtree"}} fontSize={18} sx={{ textAlign: 'left' }} color="#ffffff">
                    <br/>
                    Kripto paraları en iyi şekilde öğrenmek için topluluk gücünü kullan.  <br/><br/>
                    Herhangi bir sorun olursa bize Discord’dan da sorabilirsin!<br/><br/>
                </Typography>
                <a href="https://discord.gg/c6YckqH9Hy" style={{ textDecoration: "none",color:"#ffffff" }}>
                    <button className="guess-button" type="submit"style={{backgroundColor: "#5a59d8"}}>
                        <Typography color="#ffffff" fontSize={14} fontFamily= "figtree" fontWeight='bold' >
                            dYdX Türkiye <br/> Discord'una Katıl
                        </Typography>
                    </button>
                </a>
                </div> 
            </Grid>
            <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
                <YoutubeEmbed embedId="ZA5N11Rfces" />
            </Grid>
        </Grid>
       
    </Container>      
  );
}