import * as React from 'react';
import logo from '../assets/logo.png'; // with import

import { useState } from 'react';
import { AppBar, Box, Container, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageIcon from '@mui/icons-material/Language';
import { Link } from 'react-router-dom';

function Navbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [anchorElLang, setAnchorElLang] = React.useState(null);
  const handleOpenLangMenu = (event) => {
    setAnchorElLang(event.currentTarget);
  };
  const handleCloseLangMenu = () => {
    setAnchorElLang(null);
  };
  

  return (
    <AppBar 
      position="sticky" 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: '#28283b',
      }}
      style={{ 
        background: '#1A1A2C', 
        }}
    >
      <Container maxWidth="xl" style={{paddingBottom: "5px", paddingTop:"4px"}}>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <Link to="/">
                <img src={logo} height="48px" style={{borderRadius: "10px 10px 10px 10px", marginTop: "5px"}} />
              </Link>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <MenuItem onClick={handleCloseNavMenu} component={Link} to="/kriptoPara">
                  <Typography textAlign="center" fontFamily= "figtree">Kripto Para</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu} component={Link} to="/defi">
                  <Typography textAlign="center" fontFamily= "figtree">DEFI</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu} component={Link} to="/dydx">
                  <Typography textAlign="center" fontFamily= "figtree">dYdX</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Box>

        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Link to="https://dydx.exchange?utm_source=Mediology&utm_medium=header&utm_campaign=paid_marketing&utm_id=dydxTurkiyePaidMarketingPage">
                <img src={logo} height="48px" style={{borderRadius: "10px 10px 10px 10px", marginTop: "4px"}}/>
            </Link>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end'}}>
                <MenuItem onClick={handleCloseNavMenu} component={Link} to="/kriptoPara">
                  <Typography textAlign="center" fontFamily= "figtree">Kripto Para</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu} component={Link} to="/defi">
                  <Typography textAlign="center" fontFamily= "figtree">DeFi</Typography>
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu} component={Link} to="/dydx">
                  <Typography textAlign="center" fontFamily= "figtree">dYdX</Typography>
                </MenuItem>
            </Box>
         </Box>
         {/***
          <Box>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="lang-appbar"
            aria-haspopup="true"
            onClick={handleOpenLangMenu}
            color="inherit"
          >
            <LanguageIcon/> 
          </IconButton>
          <Menu
            id="lang-appbar"
            anchorEl={anchorElLang}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElLang)}
            onClose={handleCloseLangMenu}
            sx={{
              display: { xs: 'block', md: 'block' },
            }}
         >
          <MenuItem onClick={handleCloseLangMenu} component={Link} to="../">
            <Typography textAlign="center">TR</Typography>
          </MenuItem>
          <MenuItem onClick={handleCloseLangMenu} component={Link} to="../en">
            <Typography textAlign="center">EN</Typography>
          </MenuItem>
         </Menu>
        </Box>
          ***/}
         


        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
