import React from 'react';
import { Component } from "react";
import Navbar from '../components/navbar';
import Footer from "../components/footer";
import Body3 from '../components/body3';
import './styles.css';
import BodyKripto3 from '../components/bodyKripto3';
import ReactGA from 'react-ga';

class KriptoPara extends Component {

    render(){
        return (
            <div>
                <Navbar/>
                <Body3/>
                <BodyKripto3/>
                <Footer/>
            </div>
        );
    }
    
}

export default KriptoPara;