import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import "../App.css";
import dot from '../assets/dots2.png'; // with import
import one from '../assets/one.png'; // with import
import ss from '../assets/ss.png'; // with import
import token from '../assets/token.svg'; // with import
import p from '../assets/p.svg'; // with import
import YoutubeEmbed from "./youTubeEmbed.js";
import AppleIcon from '@mui/icons-material/Apple';

export default function BodyDydx2() {
  return (
    <Container>
        <div style={{marginBottom:"3%"}}>
            <Grid container spacing={2}>
                    <Grid item xs={12} md={9}>
                        <div className='yazı3' style={{backgroundColor: "#181825", paddingBottom:"10%", marginTop:"10%", paddingTop:"8%"}}>
                            <Typography color="#ffffff" fontSize={24} fontFamily= "figtree" fontWeight='bold' sx={{ textAlign: 'left' }}>
                                Her An, Her Yerde İşlem Aç.
                            </Typography>
                            <Typography style={{textAlign: "justify", fontFamily: "figtree"}} fontSize={18} sx={{ textAlign: 'left' }} color="#ffffff">
                                <br/>
                                Geleneksel borsaların aksine kripto borsaları 7/24 aktif olan borsalardır.<br/><br/>
                                dYdX’i ister cebindeki mobil uygulamadan (yakında Android uygulamamız da gelecek), ister bilgisayarından dilediğin gibi kullanabilirsin.<br/><br/>
                            </Typography>
                            <button className="guess-button" href='https://apps.apple.com/SG/app/id1564787350?mt=8&utm_source=google&utm_medium=landing_page&utm_campaign=paid_marketing&utm_id=app' style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "0%", backgroundColor: "#000000" }}>
                                <a href="https://dydx.onelink.me/rNzl/dydxturkiye" style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "0%", backgroundColor: "#000000", textDecoration: "none" }}>
                                    <div>
                                        <AppleIcon style={{ color: "#C8C7D8" }}/>
                                    </div>
                                    <Typography color="#ffffff" fontSize={14} fontFamily= "figtree" fontWeight='bold'>
                                        &nbsp; Mobil dYdX Uygulamasını İndir
                                    </Typography>
                                </a>    
                            </button>
                        </div>
                    </Grid>
                <Grid item xs={12} md={3}>
                    <div className="token-img-container" style={{marginLeft: "10%"}}>
                        <img src={p} alt="Token" className="token-img" />
                    </div>
                </Grid>
            </Grid>
        </div>

        <div className='yazı3' style={{backgroundColor: "#181825"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <div className="token-img-container" style={{marginLeft: "-23%",paddingRight: "23%", marginTop: "-9%",marginBottom: "-11%", padding:"0%",  borderRadius:"105px"}}>
                        <img src={ss} alt="ss" className="token-img" style={{borderRadius:"15px"}}/>
                    </div>
                </Grid>
                <Grid item xs={12} md={9}>
                        <Typography color="#ffffff" fontSize={24} fontFamily= "figtree" fontWeight='bold' sx={{ textAlign: 'left' }}>
                            Fırsatlara Özgürce Eriş
                        </Typography>
                        <Typography style={{textAlign: "justify", fontFamily: "figtree"}} fontSize={18} sx={{ textAlign: 'left' }} color="#ffffff">
                            <br/>
                            dYdX, akıllı sözleşmelerden ve blok zinciri teknolojisinden yararlanarak kullanıcıların eşler arası trade yapmalarını sağlayan bir kripto sürekli vadeli işlem platformudur.
                            <br/><br/>
                            dYdX için Merkeziyetsiz Finans (DeFi) sadece bir iş modeli değil merkezi finans tekeline karşı bir devrimdir. Merkezi finansın aksine dYdX komünitesini önemser ve kazandıklarını komünitesine geri verir.
                            <br/><br/>
                        </Typography>
                </Grid>
            </Grid>
        </div>
        <div className='yazı3' style={{backgroundColor: "#181825"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                        <Typography color="#ffffff" fontSize={24} fontFamily= "figtree" fontWeight='bold' sx={{ textAlign: 'left' }}>
                            Kim Olursan Ol Gel
                        </Typography>
                        <Typography style={{textAlign: "justify", fontFamily: "figtree"}} fontSize={18} sx={{ textAlign: 'left' }} color="#ffffff">
                            <br/>
                            Merkezi borsaların aksine, dYdX’te yalnızca cüzdanını bağlayarak trade etmeye hemen başlayabilirsin. 
                            <br/>
                        </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <div className="token-img-container" style={{marginLeft: "10%"}}>
                        <img src={dot} alt="dot" className="token-img" />
                    </div>
                </Grid>
            </Grid>
        </div>
        <Grid container spacing={2} style={{marginTop: "-5%"}}>
            <Grid item xs={12} md={9} sx={{ order: { xs: 1, md: 2 } }}>
                <div className='yazı2' style={{backgroundColor: "#181825"}}>
                    <Typography color="#ffffff" fontSize={24} fontFamily= "figtree" fontWeight='bold' sx={{ textAlign: 'left' }} >
                        Komisyon Ödemeden Trade Et ve Token Kazan
                    </Typography>
                    <Typography style={{textAlign: "justify", fontFamily: "figtree"}} fontSize={18} sx={{ textAlign: 'left' }} color="#ffffff">
                        <br/>
                        Merkezi borsalarda işlemlerin için komisyon ücreti öderken, dYdX aylık 100.000$’a kadar herhangi bir komisyon ücreti almaz.
                        <br/><br/>
                        İşlem hacmin $100.000’ın üzerinde ise sana açtığın her işlemle $DYDX token kazandırır. dYdX için önemli olan kar değil komünite maksimizasyonudur.
                        <br/><br/>
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={12} md={3} sx={{ order: { xs: 2, md: 1 } }}>
                <div className="token-img-container" style={{marginLeft: "10%"}}>
                    <img src={token} alt="Token" className="token-img" />
                </div>
            </Grid>
        </Grid>
        <div className='yazı3' style={{backgroundColor: "#181825"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                    <Typography color="#ffffff" fontSize={24} fontFamily= "figtree" fontWeight='bold' sx={{ textAlign: 'left' }}>
                        Anahtarlar Senin Değilse Tokenler de Değildir
                    </Typography>
                    <Typography style={{textAlign: "justify", fontFamily:"figtree"}} fontSize={18} sx={{ textAlign: 'left' }} color="#ffffff">
                        <br/>
                        Merkeziyetsiz borsa dYdX’i kullanıp tokenlerini kendi cüzdanında saklar, art niyetli kurumların ve hackerların tokenlerine el koymasının önüne geçebilirsin.
                        <br/><br/>
                        Merkezi borsalarda tuttuğun tokenler borsaların cüzdanlarında saklanır, bu da olası bir hack veya borsanın kripto paraları alıp gitmesi durumunda tokenlerinin de kaybolmasına sebep olur.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <div className="token-img-container" style={{marginLeft: "10%"}}>
                        <img src={one} alt="1" className="token-img" />
                    </div>
                </Grid>
            </Grid>
        </div>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
                <div className='yazı'>
                <Typography color="#ffffff" fontSize={24} fontFamily= "figtree" fontWeight='bold' sx={{ textAlign: 'left' }} >
                    Trade ederken Token Kazanmak
                </Typography>
                <Typography style={{textAlign: "justify", fontFamily: "figtree"}} fontSize={18} sx={{ textAlign: 'left' }} color="#ffffff">
                    <br/>
                    İşlem hacmin $100.000’ın üzerinde ise sana açtığın her işlemle $DYDX token kazandırır.  <br/><br/>
                    dYdX aylık 100.000$’a kadar herhangi bir komisyon ücreti almaz.<br/><br/>
                </Typography>
                <div style={{ display: "flex", justifyContent: "flex-start", marginLeft:"-4%" }}>
                    <a className="guess-button" href="https://dydx.exchange?utm_source=Mediology&utm_medium=dydx&utm_campaign=paid_marketing&utm_id=dydxTurkiyePaidMarketingPage" style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "5%", backgroundColor: "#5a59d8", textDecoration: "none" }}>
                            <Typography color="#ffffff" fontSize={14} fontFamily= "figtree" fontWeight='bold'>
                                Şimdi Trade Et
                            </Typography>
                        </a>  
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
                <YoutubeEmbed embedId="ZA5N11Rfces" />
            </Grid>
        </Grid>
        
    </Container>      
  );
}