import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import "../App.css";
import token from '../assets/4 1.png'; // with import
import kalkan from '../assets/kalkan.png'; // with import
import profil from '../assets/profil.png'; // with import
import dot from '../assets/dots2.png'; // with import
import report from '../assets/report.png'; // with import
import YoutubeEmbed from "./youTubeEmbed.js";
import { Link } from "react-router-dom";

export default function BodyDefi2() {
  return (
    <Container style={{marginBottom:"-5%"}}>
       <Grid container spacing={2}>
            <Grid item xs={12} md={9} sx={{ order: { xs: 1, md: 2 } }}>
                <div className='yazı2' style={{backgroundColor: "#181825"}}>
                    <Typography color="#ffffff" fontSize={24}  fontWeight='bold' fontFamily= "figtree" sx={{ textAlign: 'left' }}>
                        Aracısız, Açık, Şeffaf
                    </Typography>
                    <Typography style={{textAlign: "justify", fontFamily: "figtree"}} fontSize={18} sx={{ textAlign: 'left' }} color="#ffffff">
                        <br/>
                        DeFi protokolleri, açık ve şeffaf finansal ürünler ve hizmetler sağlamak için blok zinciri teknolojisini kullanır. 
                        Kullanıcı fonlarının emanetini alan bankalar ve finans kurumları gibi aracılar tarafından kontrol edilen Geleneksel Finansın (TradFi) aksine, DeFi tamamen merkeziyetsiz ve izinsizdir.
                        <br/><br/>
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={12} md={3} sx={{ order: { xs: 2, md: 1 } }}>
                <div className="token-img-container" style={{marginLeft: "10%"}}>
                    <img src={token} alt="Token" className="token-img" />
                </div>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={12} md={9} >
                <div className='yazı' style={{backgroundColor: "#181825"}}>
                    <Typography color="#ffffff" fontSize={24}  fontWeight='bold' fontFamily = "figtree" sx={{ textAlign: 'left' }} >
                        Güvenle Kullan
                    </Typography>
                    <Typography style={{textAlign: "justify", fontFamily: "figtree"}} fontSize={18} sx={{ textAlign: 'left' }} color="#ffffff">
                        <br/>
                        Akıllı sözleşmeler, ilgili olayları ve eylemleri önceden tanımlanmış şartlara ve kurallara göre gerçekleştiren, kontrol eden ve belgeleyen yazılım kodlarıdır. Bir finansal işlemin kurallarının, güvenliğinin ve şeffaflığının programlanmasını ve garanti edilmesini sağlarlar.
                        <br/><br/>
                        DeFi uygulamaları, akıllı sözleşmeler kullanarak aracılara olan ihtiyacı ortadan kaldırır ve blockchain teknolojisinin sağladığı güvenlikle hızlı, şeffaf ve güvenilir hizmetler sunar.
                        <br/><br/>
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={12} md={3} >
                <div className="token-img-container" style={{marginLeft: "10%"}}>
                    <img src={kalkan} alt="kalkan" className="token-img" />
                </div>
            </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={12} md={9} sx={{ order: { xs: 1, md: 2 } }}>
                <div className='yazı2' style={{backgroundColor: "#181825"}}>
                    <Typography color="#ffffff" fontSize={24}  fontWeight='bold' fontFamily= "figtree" sx={{ textAlign: 'left' }} >
                        Kim Olduğun Önemli Değil
                    </Typography>
                    <Typography style={{textAlign: "justify", fontFamily: "figtree"}} fontSize={18} sx={{ textAlign: 'left' }} color="#ffffff">
                        <br/>
                        Geleneksel finans kurumlarının aksine, DeFi protokolleri kullanıcıların kişisel verilerini veya bilgilerini toplamaz. DeFi bireysel kullanıcıları hakkında herhangi bir bilgiye sahip değildir, dolayısıyla kullanıcılarına karşı tarafsızdır.
                        <br/><br/>
                        DeFi için kimlik doğrulama gereklilikleri kara para aklamayı önleme regülatörleri tarafından tartışılmaktadır. Kullanıcı bakiyeleri ve işlem faaliyetleri genellikle halka açıktır.
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={12} md={3} sx={{ order: { xs: 2, md: 1 } }}>
                <div className="token-img-container" style={{marginLeft: "10%"}}>
                    <img src={profil} alt="profil" className="token-img" />
                </div>
            </Grid>
        </Grid>
        <div className='yazı3' style={{backgroundColor: "#181825"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                        <Typography color="#ffffff" fontSize={24}  fontWeight='bold' fontFamily= "figtree" sx={{ textAlign: 'left' }}>
                            Varlıklarının Gerçek Sahibi Ol
                        </Typography>
                        <Typography style={{textAlign: "justify", fontFamily: "figtree"}} fontSize={18} sx={{ textAlign: 'left' }} color="#ffffff">
                            <br/>
                            Merkezi Finansal kurumların aksine, DeFi kullanıcıları fonlarının kontrolünü ellerinde tutarken izinsiz bir şekilde finansal hizmetlere erişimlerini sağlar. <br/><br/>
                            Kullanıcılar varlıklarını blockchain teknolojisi güvencesiyle sadece kendi erişimlerinin olduğu cüzdanlarda tutar, herhangi bir otorite tarafından izin almadan istedikleri gibi kullanabilirler.<br/><br/>
                        </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <div className="token-img-container" style={{marginLeft: "10%"}}>
                        <img src={dot} alt="dot" className="token-img" />
                    </div>
                </Grid>
            </Grid>
        </div>
        <div className='yazı3' style={{backgroundColor: "#181825"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography color="#ffffff" fontSize={24} fontFamily= "figtree" fontWeight='bold' >
                        dYdX 2022 Ekosistem Raporu
                    </Typography>
                    <Link to="https://medium.com/@dYdXTurkey/2022-ekosistem-y%C4%B1ll%C4%B1k-raporu-82361cfdc32b">
                        <div className="token-img-container" style={{marginTop: "3%"}}>
                            <img src={report} alt="report" className="token-img" />
                        </div>
                    </Link>
                </Grid>
                <Grid item xs={12} md={6}>
                        <Grid item xs={12} md={8} style={{marginLeft:"18%" }}>
                            <YoutubeEmbed embedId="iLi6JNPME8U" style={{ width: "100%", height: "auto", padding:"50%" }} />
                        </Grid>
                        <Typography style={{textAlign: "justify", fontFamily: "figtree"}} fontSize={18} sx={{ textAlign: 'left' }} color="#ffffff">
                            DeFi’nin fırsatlarla dolu dünyasına giriş yap, bir cüzdan aç!<br/><br/>
                        </Typography>
                        <a href="https://dydx.exchange?utm_source=Mediology&utm_medium=DeFi&utm_campaign=paid_marketing&utm_id=dydxTurkiyePaidMarketingPage" style={{ textDecoration: "none",color:"#ffffff" }}>
                            <button className="guess-button" type="submit"style={{backgroundColor: "#5a59d8"}}>
                                <Typography color="#ffffff" fontSize={14} fontFamily= "figtree" fontWeight='bold' >
                                        Hemen DeFi'ye adım at
                                </Typography>
                            </button>
                        </a>
                </Grid>
            </Grid>
        </div>
    </Container>      
  );
}