import { useState } from "react";
import { Link } from "react-router-dom";
import React, { Component, useEffect } from "react";
import "./styles.css";
import "../App.css";
import pc from '../assets/freedom.jpg'; // with import
import berber from '../assets/berber.jpg'; // with import
import trading from '../assets/borsa.png'; // with import
import Typography from '@mui/material/Typography';
import wave from '../assets/wave.mp4';
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga';
import { FaDiscord } from 'react-icons/fa';
import Navbar from "../components/navbar";
import Footer from "../components/footer";

function Welcome() {

    return (
        <div>
            <Navbar/>
            <div style={{marginTop:'-3%', width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Link to="/kriptopara" style={{ marginBottom: '4%',textDecoration: 'none' }}>
                    <div className="box-wide2">
                        <Typography color="#ffffff" fontFamily= "figtree" fontSize={22}>
                            Kripto Dünyasını Ustasından Öğren 💸
                        </Typography>
                    </div>
                </Link>
                <Link to="https://mee6.xyz/i/P0F495v1Lo" style={{ marginBottom: '4%',textDecoration: 'none' }}>
                    <div className="box-wide2">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography color="#ffffff" fontFamily="figtree" fontSize={22}>
                                Kazanan Ekibe Hemen Katıl
                            </Typography>
                            <FaDiscord style={{ color: "#C8C7D8", fontSize: "28px", marginTop: "5px" }} />
                        </div>
                    </div>
                </Link>
                <Link to="https://dydx.exchange?utm_source=Mediology&utm_medium=dydx&utm_campaign=paid_marketing&utm_id=dydxTurkiyePaidMarketingPage" style={{ marginBottom: '5%',textDecoration: 'none' }}>
                    <div className="box-wide2" >
                        <Typography color="#ffffff" fontFamily="figtree" fontSize={22}style={{ paddingLeft:'5%',paddingRight:'5%'  }}>
                            En Büyük Merkeziyetsiz Borsa dYdX'e Git 🏦
                        </Typography>
                    </div>
                </Link>
            </div>
            <Footer/>
        </div>
        
    );
}
  
  

export default Welcome;