import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import "../App.css";
import b from '../assets/b.png'; // with import
import ReactGA from 'react-ga';
import { useEffect } from 'react';

export default function Body3() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, []);
    
  return (
    <Container>
       <Grid container spacing={2} style={{paddingTop: "3%"}}>
            <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
                <div className='yazı' >
                    <Typography color="#ffffff" fontSize={24}  fontWeight='bold' fontFamily= "figtree" sx={{ textAlign: 'left' }} >
                        Geleceğin Parası: Kripto Paralar
                    </Typography>
                    <Typography style={{textAlign: "justify",fontFamily: "figtree"}} fontSize={18} sx={{ textAlign: 'left' }} color="#ffffff">
                        <br/>
                        DeFi'yi en iyi şekilde öğrenmek için topluluk gücünü kullan. <br/><br/>
                        dYdX Türkiye Discord'una katılarak Kripto paralar hakkında en aktif komünite ile fikir alışverişi yap ve deneyimlerinden faydalan.
                        <br/><br/>
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "flex-start", marginLeft:"-5%" }}>
                            <a className="guess-button" href="https://mee6.xyz/i/V5zf5bJvEe" style={{ display: "flex",fontFamily: "figtree", alignItems: "center", justifyContent: "center", marginLeft: "5%", backgroundColor: "#5a59d8", textDecoration: "none" }}>
                                <Typography color="#ffffff" fontSize={14} fontWeight='bold'>
                                    dYdX Türkiye <br/> Discord'una Katıl
                                </Typography>
                            </a>  
                     </div>
                </div>
            </Grid>
            <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
                <div className="token-img-container" style={{marginTop: "3%"}}>
                    <img src={b} alt="bitcoin" className="token-img" />
                </div>
            </Grid>
        </Grid>
    </Container>      
  );
}