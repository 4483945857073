import React from 'react';
import { Component } from "react";
import Navbar from '../components/navbar';
import Footer from "../components/footer";
import Body1 from '../components/body1';
import './styles.css';
import BodyDydx2 from '../components/BodyDydx2';
import ReactGA from 'react-ga';

class Dydx extends Component {

    render(){
        return (
            <div>
                <Navbar />
                <Body1 />
                <BodyDydx2/>
                <Footer />
            </div>
        );
    }
}

export default Dydx;