import React from 'react';

const baseUrl = "https://www.dydxileogren.com";

function generateSitemap() {
  const pages = [
    { url: '/', changefreq: 'weekly' },
    { url: '/kriptopara', changefreq: 'monthly' },
    { url: '/defi', changefreq: 'monthly' },
    { url: '/dydx', changefreq: 'monthly' },
    // Diğer sayfaları buraya ekleyin
  ];

  const currentDate = new Date().toISOString();

  const xml = `<?xml version="1.0" encoding="UTF-8"?>
    <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
      ${pages
        .map(
          (page) => `
          <url>
            <loc>${baseUrl}${page.url}</loc>
            <lastmod>${currentDate}</lastmod>
            <changefreq>${page.changefreq}</changefreq>
          </url>
        `
        )
        .join('')}
    </urlset>`;

  return xml;
}

export default generateSitemap;
