import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import "../App.css";
import phone from '../assets/phone.svg'; // with import
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { tableCellClasses } from "@mui/material/TableCell";
import ReactGA from 'react-ga';

export default function Body1() {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
        ReactGA.pageview(window.location.pathname)
    }, []);


    const fetchData = async () => {
        try {
            const response = await axios.get('https://api.dydx.exchange/v3/markets');
            setData(response.data.markets);
        } catch (error) {
            console.log(error);
        }
    };

    const marketCount = Object.keys(data).length;
    let totalVolume = 0;
    for (const market in data) {
        const volume24H = parseFloat(data[market].volume24H);
        totalVolume += volume24H;
    }
    const formattedTotalVolume = `$${(totalVolume / 1000000).toFixed(0)} M`;
    
    let totalTrade = 0;
    for (const market in data) {
        const trade24H = parseFloat(data[market].trades24H);
        totalTrade += trade24H;
    }
    const formattedTotalTrade = `${(totalTrade / 1000).toFixed(0)}K`;
    
    let totalInterest = 0;
    for (const market in data) {
        const interest = parseFloat(data[market].openInterest);
        totalInterest += interest;
    }
    const formattedInterest = `$${(totalInterest / 1000000).toFixed(0)} M`;

    return (
        
        <Container>
        <Grid container spacing={2} style={{paddingTop: "3%"}}>
                <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
                    <div className='yazı' style={{marginTop: "30%"}}>
                        <Typography className='figtree' color="#ffffff" fontSize={24}  fontWeight='bold' style={{fontFamily: "figtree"}} sx={{ textAlign: 'left' }}>
                            Geleceğin Borsası: dYdX
                        </Typography>
                        <Typography className ='figtree' style={{textAlign: "justify", fontFamily: "figtree"}} fontSize={18} sx={{ textAlign: 'left' }} color="#ffffff">
                            <br/>
                            DeFi'yi en iyi şekilde öğrenmek için topluluk gücünü kullan. <br/><br/><br/>
                            dYdX Türkiye Discord'una katılarak DeFi'nin en aktif komunitesi ile fikir alışverişi yap ve deneyimlerinden faydalan.<br/><br/>
                        </Typography>
                        
                        <div style={{ display: "flex", justifyContent: "flex-start", marginLeft:"-4%" }}>
                            <a className="guess-button" href="https://dydx.exchange?utm_source=Mediology&utm_medium=dydx&utm_campaign=paid_marketing&utm_id=dydxTurkiyePaidMarketingPage" style={{ display: "flex", alignItems: "center", fontFamily: "figtree", justifyContent: "center", marginLeft: "5%", backgroundColor: "#5a59d8", textDecoration: "none" }}>
                                <Typography color="#ffffff" fontSize={14} fontWeight='bold'>
                                    Şimdi Trade Et
                                </Typography>
                            </a>  
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
                    <div className="token-img-containera" style={{marginTop: "3%"}}>
                        <img src={phone} alt="exchangeApp" style={{ maxWidth: "100%", height: "auto" }} />
                    </div>
                </Grid>
            </Grid>
            <div className="blok" style={{ height:"auto", backgroundColor: "#181825", paddingLeft:"5%", marginRight:"-1%" , marginTop:"8%", }}>
                <TableContainer>
                    <Table sx={{
                        [`& .${tableCellClasses.root}`]: {
                        borderBottom: "none"
                        },
                        tableLayout: "fixed" 
                    }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                <Typography color="#808080" fontSize={14}  fontWeight='bold' >
                                    Marketler
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography color="#808080" fontSize={14}  fontWeight='bold' >
                                    Günlük Hacim
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography color="#808080" fontSize={14}  fontWeight='bold' >
                                    Günlük Trade Sayısı
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography color="#808080" fontSize={14}  fontWeight='bold' >
                                    Açık Fonlama Miktarı
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow>
                            <TableCell align="center">
                                <Typography color="#ffffff" fontSize={16}  fontWeight='bold' >
                                    {marketCount}
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography color="#ffffff" fontSize={16}  fontWeight='bold' >
                                    {formattedTotalVolume}
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography color="#ffffff" fontSize={16}  fontWeight='bold' >
                                    {formattedTotalTrade}
                                </Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography color="#ffffff" fontSize={16}  fontWeight='bold' >
                                    {formattedInterest}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Container>      
    );
}